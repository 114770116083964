<template>
  <!--秒杀-竞买-->
  <div v-if="seckill.length || auction.length" class="seckill-auction">
    <div class="main box">
      <!--tit-->
      <div class="part-tit">
        <div class="tit-tab">
          <!--秒杀-->
          <div v-if="seckill.length" class="item" :class="{current:showType == 'seckill'}" @click="showType = 'seckill'">
            <img src="../../assets/images/ic_tit031.png" alt="img">
          </div>
          <!--竞买-->
          <div v-if="auction.length" class="item" :class="{current:showType == 'auction'}" @click="showType = 'auction'">
            <img src="../../assets/images/ic_tit032.png" alt="img">
          </div>
        </div>
        <nuxt-link :to="showType == 'seckill' ? '/seckill' : '/mall/auction'" class="more">
          更多<img src="../../assets/images/ic_tit_more.png" alt="img">
        </nuxt-link>
      </div>
      <!--content-->
      <div class="content">
        <!--秒杀-->
        <div v-show="showType=='seckill'" class="seckill">
          <nuxt-link v-for="item in seckill" :key="item.id" :to="'/seckill/'+item.id" class="item">
            <div class="img-box">
              <img :src="item.cover" :alt="item.name">
            </div>
            <div class="goods-info">
              <div class="tit">{{ item.name }}</div>
              <div class="price">
                <span>¥{{ item.goods_price }}</span>
                <span class="original">¥{{ item.goods_marketprice }}</span>
              </div>
              <nuxt-link to="">
                <div class="btn">抢购</div>
              </nuxt-link>
            </div>
          </nuxt-link>
        </div>
        <!--竞买-->
        <div v-show="showType == 'auction'" class="auction">
          <nuxt-link v-for="item in auction" :key="item.id" :to="'/mall/auction/'+item.id" class="item">
            <div class="img-box">
              <img :src="item.cover" :alt="item.name">
            </div>
            <div class="goods-info">
              <div class="tit">{{ item.name }}</div>
              <div class="price">
                <div class="tips">当前价</div>
                <div>¥{{ item.goods_now_price }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeckillAuction',
  props: {
    seckill: {
      type: [Array, Object],
      default () {
        return []
      }
    },
    auction: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  },
  data () {
    return {
      showType: 'seckill'
    }
  },
  created () {
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.seckill-auction {
  background-color: #f2f2f2;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main {
    padding-bottom: 60px;

    .part-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tit-tab {
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 74px;
          background-color: #dedede;
          border-radius: 4px 4px 0 0;
          margin-right: 10px;

          &.current {
            background-color: $primaryColor;
          }

          &:hover {
            background-color: $primaryColor;
          }

          img {
            width: 54px;
          }
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        img {
          height: 15px;
        }
      }
    }

    .content {
      background-color: #fff;
      padding: 30px 25px;

      .seckill {
        display: flex;
        flex-wrap: wrap;

        .item {
          border-radius: 5px;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.13);
          overflow: hidden;
          width: 218px;
          margin-top: 20px;

          &:not(:first-child) {
            margin-left: 15px;
          }

          &:nth-child(5n+1) {
            margin-left: 0;
          }

          .img-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 218px;
            height: 155px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .goods-info {
            padding: 10px;

            .tit {
              font-size: 18px;
              color: #444;
              margin-bottom: 10px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .price {
              span {
                font-size: 18px;
                font-weight: 600;
                color: #ea4d4d;

                &.original {
                  font-size: 14px;
                  color: #bcbcbc;
                  text-decoration: line-through;
                  margin-left: 8px;
                }
              }
            }

            .btn {
              font-size: 16px;
              font-weight: 500;
              color: #fff;
              width: 60px;
              background: #ea4d4d;
              padding: 5px;
              border-radius: 2px;
              text-align: center;
              cursor: pointer;
              margin: 15px 0;
            }
          }
        }
      }

      .auction {
        display: flex;
        flex-wrap: wrap;

        .item {
          border-radius: 5px;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.13);
          overflow: hidden;
          width: 218px;
          margin-top: 20px;

          &:not(:first-child) {
            margin-left: 15px;
          }

          &:nth-child(5n+1) {
            margin-left: 0;
          }

          .img-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 218px;
            height: 155px;
            overflow: hidden;

            img {
              height: 100%;
              max-width: 100%;
            }
          }

          .goods-info {
            padding: 10px;

            .tit {
              font-size: 18px;
              color: #444;
              margin-bottom: 10px;
              height: 50px;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
            }

            .price {
              font-size: 18px;
              font-weight: 600;
              color: #ea4d4d;

              .tips {
                font-size: 14px;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
