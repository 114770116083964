<template>
  <!--首页-->
  <div class="page-home">
    <top-bar />
    <div class="content">
      <focus-news-group :lists="indexData.top_swiper_ad" />
      <cloud-exhibition :lists="indexData.cloud_expo" />
      <cultural-mall :lists="indexData.goods_model" />
      <seckill-auction :seckill="indexData.seckill_model" :auction="indexData.auction_model" />
      <live :lists="indexData.live_model" />
      <video-news :lists="indexData.news_video_model" />
      <cloud-data :lists="indexData.three_dimensional_model" />
      <cultural-news :lists="indexData.news_normal_model" />
    </div>
    <bottom-bar />
  </div>
</template>

<script>
import TopBar from '@/components/top-bar'
import BottomBar from '@/components/bottom-bar'
import FocusNewsGroup from '@/components/home/focus-news-group'
import CloudExhibition from '@/components/home/cloud-exhibition'
import CulturalMall from '@/components/home/cultural-mall'
import SeckillAuction from '@/components/home/seckill-auction'
import Live from '@/components/home/live'
import VideoNews from '@/components/home/video-news'
import CloudData from '@/components/home/cloud-data'
import CulturalNews from '@/components/home/cultural-news'
import { commonHttpInteractor } from '@/core'

export default {
  name: 'IndexPage',
  components: {
    CulturalNews,
    CloudData,
    VideoNews,
    Live,
    SeckillAuction,
    CulturalMall,
    CloudExhibition,
    FocusNewsGroup,
    BottomBar,
    TopBar
  },
  async asyncData (ctx) {
    const { data } = await commonHttpInteractor.indexData({ sid: 4 })
    const initData = {
      top_swiper_ad: [],
      cloud_expo: [],
      goods_model: [],
      auction_model: [],
      seckill_model: [],
      live_model: [],
      news_video_model: [],
      three_dimensional_model: [],
      news_normal_model: []
    }
    data && data.map(item => {
      initData[item.type] = item.con
    })
    return {
      indexData: initData
    }
  },
  data () {
    return {}
  },
  created () {
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.page-home {
  padding-top: $topHeight;
  .content {
    min-height: calc(100vh - #{$topHeight} - #{$bottomHeight});
  }
}
</style>
