<template>
  <!--视频新闻-->
  <div v-if="lists.length" class="video-news">
    <div class="main box">
      <!--tit-->
      <div class="part-tit">
        <div class="tit-tab">
          <div class="item current">
            <img src="../../assets/images/ic_tit05.png" alt="img">
          </div>
        </div>
        <nuxt-link to="/video-news" class="more">
          更多
          <img src="../../assets/images/ic_tit_more.png" alt="img">
        </nuxt-link>
      </div>
      <!--content-->
      <div class="content">
        <nuxt-link v-for="item in lists" :key="item.id" :to="'/video-news/'+item.id" class="item">
          <div class="img-box">
            <img :src="item.cover" :alt="item.title">
          </div>
          <div class="video-info">
            <div class="tit">{{ item.title }}</div>
            <div class="date">{{ item.publish_time }}</div>
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoNews',
  props: {
    lists: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.video-news {
  background-color: #f2f2f2;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main {
    padding-bottom: 60px;

    .part-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tit-tab {
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 74px;
          background-color: #dedede;
          border-radius: 4px 4px 0 0;
          margin-right: 10px;

          &.current {
            background-color: $primaryColor;
          }

          &:hover {
            background-color: $primaryColor;
          }

          img {
            width: 54px;
          }
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        img {
          height: 15px;
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 30px 25px;

      .item {
        &:not(:nth-child(4n + 1)) {
          margin-left: 10px;
        }

        &:nth-child(n + 5) {
          margin-top: 35px;
        }

        .img-box {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          width: 280px;
          height: 210px;
          overflow: hidden;

          img {
            max-width: 100%;
            height: 100%;
          }

          .msg {
            position: absolute;
            right: 15px;
            bottom: 15px;
            font-size: 14px;
            color: #fff;
            padding: 5px 10px 5px 5px;
            background-color: rgba(0, 0, 0, .45);
          }
        }

        .video-info {
          width: 280px;

          .tit {
            font-size: 18px;
            font-weight: 500;
            color: #444;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 15px 0 5px;
          }

          .date {
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
  }
}
</style>
