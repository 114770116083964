<template>
  <!--直播-->
  <div v-if="lists.length" class="live">
    <div class="main box">
      <!--tit-->
      <div class="part-tit">
        <div class="tit-tab">
          <div class="item current">
            <img src="../../assets/images/ic_tit04.png" alt="img">
          </div>
        </div>
        <nuxt-link to="/live" class="more">
          LIVE
          <img src="../../assets/images/ic_tit_more.png" alt="img">
        </nuxt-link>
      </div>
      <!--content-->
      <div class="content">
        <nuxt-link v-for="item in lists" :key="item.id" :to="'/live/'+item.id" class="item">
          <img :src="item.cover" :alt="item.name">
          <div class="label">{{ item.type_text }}</div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Live',
  props: {
    lists: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.live {
  background-color: #f2f2f2;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main {
    padding-bottom: 60px;

    .part-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tit-tab {
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 74px;
          background-color: #dedede;
          border-radius: 4px 4px 0 0;
          margin-right: 10px;

          &.current {
            background-color: $primaryColor;
          }

          &:hover {
            background-color: $primaryColor;
          }

          img {
            width: 54px;
          }
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        img {
          height: 15px;
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 30px 25px;

      .item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 370px;
        height: 180px;
        overflow: hidden;
        margin-top: 20px;

        &:not(:first-child) {
          margin-left: 15px;
        }

        &:nth-child(3n+1) {
          margin-left: 0;
        }

        .label {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 80px;
          padding: 5px;
          font-size: 16px;
          color: #ffffff;
          background: linear-gradient(90deg, #9361e6 3%, #9e96c5);
          border-radius: 2px;
          text-align: center;
        }
      }
    }
  }
}
</style>
