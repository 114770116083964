<template>
  <!--文品商城-->
  <div v-if="goodsLists.length" class="cultural-mall">
    <div class="main box">
      <!--tit-->
      <div class="part-tit">
        <div class="tit-tab">
          <div class="item current">
            <img src="../../assets/images/ic_tit02.png" alt="img">
          </div>
        </div>
        <nuxt-link to="/goods" class="more">
          更多
          <img src="../../assets/images/ic_tit_more.png" alt="img">
        </nuxt-link>
      </div>
      <!--content-->
      <div class="content">
        <div v-for="(goods,index) in goodsLists" :key="index" class="group">
          <nuxt-link v-for="item in goods" :key="item.id" :to="'/goods/'+item.id" class="item">
            <div class="img-box">
              <img :src="item.cover" :alt="item.name">
            </div>
            <div class="goods-info">
              <div class="tit">{{ item.name }}</div>
              <div class="msg">
                <div class="price">¥{{ item.goods_price }}</div>
                <div class="add"><!--ss-->
                  <img src="../../assets/images/ic_goods_add.png" alt="ic">
                </div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CulturalMall',
  props: {
    lists: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  },
  computed: {
    goodsLists () {
      return _.chunk(this.lists, 4)
    }
  }
}
</script>

<style scoped lang="scss">
.cultural-mall {
  background-color: #f2f2f2;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main {
    padding-bottom: 60px;

    .part-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tit-tab {
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 74px;
          background-color: #dedede;
          border-radius: 4px 4px 0 0;
          margin-right: 10px;

          &.current {
            background-color: $primaryColor;
          }

          &:hover {
            background-color: $primaryColor;
          }

          img {
            width: 54px;
          }
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        img {
          height: 15px;
        }
      }
    }

    .content {
      background-color: #fff;
      padding: 30px 25px;

      .group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        .item {
          width: 250px;
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.13);

          .img-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 250px;
            height: 220px;
            overflow: hidden;
          }

          .goods-info {
            .tit {
              font-size: 18px;
              color: #444;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              margin: 10px;
              height: 50px;
            }

            .msg {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px;

              .price {
                font-size: 18px;
                font-weight: 600;
                color: #ea4d4d;
              }

              .add {
                cursor: pointer;

                img {
                  width: 20px;
                }
              }
            }
          }

          &:not(:first-child) {
            .img-box {
              img {
                height: 100%;
              }
            }
          }

          &:first-child {
            position: relative;
            width: 340px;

            .img-box {
              width: 340px;
              //height: auto;
              overflow: hidden;
            }

            .goods-info {
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, .4);

              .tit {
                color: #fff;
                -webkit-line-clamp: 1;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
</style>
