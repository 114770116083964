<template>
  <!--文化资讯-->
  <div v-if="lists.length" class="cultural-news">
    <div class="main box">
      <!--tit-->
      <div class="part-tit">
        <div class="tit-tab">
          <div class="item current">
            <img src="../../assets/images/ic_tit07.png" alt="img">
          </div>
        </div>
        <nuxt-link to="/news" class="more">
          更多<img src="../../assets/images/ic_tit_more.png" alt="img">
        </nuxt-link>
      </div>
      <!--content-->
      <div v-if="lists[0]" class="content">
        <nuxt-link :to="'/news/' + lists[0].id" class="news-detail">
          <div class="img-box">
            <img :src="lists[0].cover" :alt="lists[0].title">
          </div>
          <div class="tit">{{ lists[0].title }}</div>
          <div class="date">{{ lists[0].publish_time }}</div>
          <div class="txt">{{ lists[0].summary }}</div>
        </nuxt-link>
        <div class="news-list">
          <nuxt-link v-for="item in newsLists" :key="item.id" :to="'/news/' + item.id" class="item">
            <div class="date">
              <div class="year">{{ item.year }}</div>
              <div class="month">{{ item.mounthDay }}</div>
            </div>
            <div class="news">
              <div class="tit">{{ item.title }}</div>
              <div class="intro">{{ item.summary }}</div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CulturalNews',
  props: {
    lists: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  },
  computed: {
    newsLists () {
      const lists = []
      this.lists.map((item, index) => {
        const date = item.publish_time.split(' ')[0]
        const year = date.split('-')[0]
        const mounthDay = date.split('-')[1] + '/' + date.split('-')[2]
        const { id, title, summary, cover } = item
        if (index) {
          lists.push({
            id,
            title,
            summary,
            cover,
            year,
            mounthDay
          })
        }
      })

      return lists
    }
  }
}
</script>

<style scoped lang="scss">
.cultural-news {
  background-color: #f2f2f2;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main {
    padding-bottom: 60px;

    .part-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tit-tab {
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 74px;
          background-color: #dedede;
          border-radius: 4px 4px 0 0;
          margin-right: 10px;

          &.current {
            background-color: $primaryColor;
          }

          &:hover {
            background-color: $primaryColor;
          }

          img {
            width: 54px;
          }
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        img {
          height: 15px;
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 30px 25px;

      .news-detail {
        width: 450px;
        padding: 10px;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.17);

        .img-box {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 250px;
          overflow: hidden;
        }

        .tit {
          font-size: 18px;
          font-weight: 500;
          color: #49486d;
          margin: 15px 0 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .date {
          font-size: 14px;
          color: #999;
        }

        .txt {
          font-size: 14px;
          color: #444;
          margin: 15px 0;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
      }

      .news-list {
        display: flex;

        .item {
          padding: 0 40px;

          .date {
            text-align: right;
            color: #999;
            margin-bottom: 45px;

            .year {
              font-size: 16px;
              font-weight: 500;
            }

            .month {
              font-size: 22px;
              font-weight: 600;
            }
          }

          .news {
            display: flex;
            flex-direction: row-reverse;
            -webkit-flex-direction: row-reverse;
            text-align: right;
            line-height: 22px;

            .tit {
              font-size: 18px;
              font-weight: 600;
              color: #222222;
              width: 22px;
              padding: 30px 0;
              margin-left: 20px;
              max-height: calc(22px * 10 + 34px);
              overflow: hidden;
              border-top: 4px solid #999;
              text-align: center;
              word-break: break-word;
              //white-space: nowrap;
              text-overflow: ellipsis;
            }

            .intro {
              font-size: 14px;
              font-weight: 600;
              color: #999999;
              width: 44px;
              padding: 30px 0;
              max-height: calc(22px * 12);
              overflow: hidden;
              letter-spacing: 2px;
              writing-mode: vertical-rl;
            }
          }

          &:not(:last-child) {
            border-right: 1px solid #bfbfbf;
          }
        }
      }
    }
  }
}
</style>
