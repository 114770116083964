<template>
  <!--云展会-->
  <div v-if="lists.length" class="cloud-exhibition">
    <div class="main box">
      <!--tit-->
      <div class="part-tit">
        <div class="tit-tab">
          <div class="item current">
            <img src="../../assets/images/ic_tit01.png" alt="img">
          </div>
        </div>
        <nuxt-link to="/dynamic" class="more">
          更多
          <img src="../../assets/images/ic_tit_more.png" alt="img">
        </nuxt-link>
      </div>
      <!--content-->
      <div class="content">
        <div class="main-tit">
          <img src="../../assets/images/img_part_01.png" alt="img">
        </div>
        <div class="list">
          <nuxt-link v-for="item in lists" :key="item.id" :to="'/dynamic/'+item.id" class="item">
            <div class="sub-tit">
              <div class="title-name">{{ item.name }}</div>
            </div>
            <div class="img-box">
              <img :src="item.banner_url" :alt="item.name">
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloudExhibition',
  props: {
    lists: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cloud-exhibition {
  background-image: linear-gradient(180deg, #f9f4ef, #f2f2f2);

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main {
    padding-top: 60px;

    .part-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tit-tab {
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 74px;
          background-color: #dedede;
          border-radius: 4px 4px 0 0;
          margin-right: 10px;

          &.current {
            background-color: $primaryColor;
          }

          &:hover {
            background-color: $primaryColor;
          }

          img {
            width: 54px;
          }
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        img {
          height: 15px;
        }
      }
    }

    .content {
      background: url("../../assets/images/img_part_bg.jpg") no-repeat top center;
      background-size: cover;
      padding-bottom: 120px;

      .main-tit {
        margin: 20px 0 60px;

        img {
          height: 120px;
          margin: 0 auto;
          cursor: pointer;
        }
      }

      .list {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          .sub-tit {
            margin-bottom: -20px;
            z-index: 1;

            img {
              height: 40px;
            }

            .title-name {
              border-radius: 10px;
              width: 180px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: #9e8360;
              background: rgba(157, 131, 96, .8);
              color: #FFFFFF;
              font-weight: bolder;
              overflow: hidden;
            }
          }

          .img-box {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 10px 10px;
            overflow: hidden;
            width: 374px;
            height: 180px;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
