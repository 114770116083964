<template>
  <!--云数据-3D可视化-->
  <div v-if="lists.length" class="cloud-data">
    <div class="main box">
      <!--tit-->
      <div class="part-tit">
        <div class="tit-tab">
          <div class="item current">
            <img src="../../assets/images/ic_tit06.png" alt="img">
          </div>
          <div class="item sub-item">
            <img src="../../assets/images/ic_tit061.png" alt="img">
            <div>3D可视化</div>
          </div>
        </div>
        <nuxt-link to="/relic" class="more">
          更多
          <img src="../../assets/images/ic_tit_more.png" alt="img">
        </nuxt-link>
      </div>
      <!--content-->
      <div class="content">
        <div v-for="(item, index) in lists" :key="index" class="item">
          <nuxt-link :to="'/relic/'+item.id" class="imgs-avartar">
            <div class="back" :style="{backgroundImage: 'url(' + backImages[index % 3].img + ')'}">
              <div class="back-color" :style="{backgroundColor: colors[index % 3].val }" />
              <img :src="item.cover" alt="">
              <div class="name-en">
                <div>{{ item.name }}</div>
                <div>{{ item.english }}</div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloudData',
  props: {
    lists: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  },
  data () {
    return {
      backImages: [
        { img: require('~/assets/images/relic_index_back1.png') },
        { img: require('~/assets/images/relic_index_back2.png') },
        { img: require('~/assets/images/relic_index_back3.png') }
      ],
      colors: [
        { val: '#a9885b' },
        { val: '#af6452' },
        { val: '#d2b085' }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.cloud-data {
  background-color: #f2f2f2;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .main {
    padding-bottom: 60px;

    .part-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tit-tab {
        display: flex;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 74px;
          background-color: #dedede;
          border-radius: 4px 4px 0 0;
          margin-right: 10px;

          &.current {
            background-color: $primaryColor;
          }

          &:hover {
            background-color: $primaryColor;
          }

          img {
            width: 54px;
          }
        }

        .sub-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
          border-top: 4px solid $primaryColor;
          border-radius: 0;
          font-size: 14px;
          font-weight: 500;
          color: #666;

          &:hover {
            background-color: #fff;
          }

          img {
            width: 26px;
            margin-bottom: 6px;
          }
        }
      }

      .more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        img {
          height: 15px;
        }
      }
    }

    .content {
      display: flex;
      background-color: #fff;
      padding: 30px 25px;

      .item {
        display: flex;
        flex-direction: column;
        width: 360px;
        margin-bottom: 30px;
        cursor: pointer;

        &:not(:nth-child(3n + 1)) {
          margin-left: 30px;
        }

        .imgs-avartar {
          position: relative;
          width: 100%;
          height: 160px;
          border-radius: 10px;
          overflow: hidden;

          .back {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            .back-color {
              position: absolute;
              left: 35px;
              top: 50%;
              transform: translateY(-50%);
              width: 96px;
              height: 96px;
              border-radius: 100%;
            }

            img {
              position: absolute;
              z-index: 2;
              left: 35px;
              top: 50%;
              transform: translateY(-50%);
              width: 110px;
              height: 110px;
            }

            .name-en {
              position: absolute;
              left: 155px;
              top: 50%;
              transform: translateY(-50%);

              > div {
                &:first-child {
                  font-size: 24px;
                  color: #fff;
                }

                &:last-child {
                  font-size: 20px;
                  color: #656573;
                }
              }
            }
          }
        }
      }

      .item {
        flex: 1;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
