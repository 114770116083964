<template>
  <!--焦点新闻组-->
  <div class="focus-news-group">
    <div class="main box">
      <!--左-->
      <div class="news-banner">
        <el-carousel height="530px" arrow="never">
          <el-carousel-item v-for="item in lists" :key="item.id">
            <a :href="item.url">
              <img :src="item.img" :alt="item.name">
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--右-->
      <div class="qrcode-group">
        <div class="group">
          <div class="tit">移动文化云</div>
          <div class="item">
            <img src="../../assets/images/img_qrcode_ios.png" alt="img">
            <div>IOS版本</div>
          </div>
          <div class="item">
            <img src="../../assets/images/img_qrcode_and.png" alt="img">
            <div>安卓版</div>
          </div>
        </div>
        <div class="group">
          <div class="tit">微信端</div>
          <div class="item">
            <img src="../../assets/images/img_qrcode_wechat.png" alt="img">
            <div>微信端</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FocusNewsGroup',
  props: {
    lists: {
      type: [Array, Object],
      default () {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
.focus-news-group {
  padding: 60px 0 40px;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  .main {
    display: flex;
    justify-content: space-between;

    .news-banner {
      width: 1060px;

      img {
        width: 100%;
      }

      .el-carousel__item {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .el-carousel__indicators--horizontal {
        left: auto;
        right: 10px;
        transform: none;
      }

      .el-carousel__button {
        width: 9px;
        height: 6px;
        border-radius: 1px;
      }
    }

    .qrcode-group {
      width: 100px;
      display: flex;
      flex-direction: column;

      .group {
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 4px 4px 0 0;
        border: 1px solid #cdcdcd;

        .tit {
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          background-color: $primaryColor;
          padding: 5px;
          margin: -1px;
          border-radius: 4px 4px 0 0;
        }

        .item {
          font-size: 12px;
          color: #666;
          padding: 30px 0;
          cursor: pointer;

          img {
            width: 70px;
          }
        }

        &:nth-child(1) {
          flex: 2;
          border-bottom: none;
          margin-bottom: -2px;
        }

        &:nth-child(2) {
          flex: 1;
          border-radius: 0 0 4px 4px;
        }
      }
    }
  }
}
</style>
